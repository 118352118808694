// noinspection HtmlUnknownBooleanAttribute
import React, {useState} from 'react';
import "./scrollcard.scss";
import ParallaxSection from "../parallax/parallaxSection";
import useWindow from "../../hooks/useWindow";


function Desktop ({title,tagline,children,className,buttons,imgOne,imgTwo,down}:any) {
    let isReactElement = tagline?.type;
    return (
        <ParallaxSection className={`section-component ${down?"down":""} ${className || ""}`}>
            <div data-scroll data-scroll-speed={1} className="image-section">
                <img className={"foreground"} data-scroll data-scroll-speed={2} src={imgOne} alt="matt and leslie"/>
                <img className={"background"} data-scroll data-scroll-speed={1} src={imgTwo} alt="matt and leslie"/>
            </div>
            <div className="divider" data-scroll data-scroll-speed="0"/>
            <div data-scroll data-scroll-speed="0" className="content-wrapper">
                <h1>
                    {title}
                </h1>
                { isReactElement ? tagline : <h2>{tagline}</h2> }
                <p>
                    {children}
                </p>
                <div className="button-container">
                    {buttons.map((button:any,index:number)=>(<span key={index}> {button} </span> )) }
                </div>
            </div>
        </ParallaxSection>
    )
}
function Mobile ({imgOne, title, tagline, children, buttons, className}:any) {
    let isReactElement = tagline?.type;
    return (
    <div className={`lmntl-card-mobile ${className || ""}`}>
        <img src={imgOne} alt="couch reading a book"/>
        <div className="lmntl-text-content">
            <h2>{title}</h2>
            {isReactElement ? tagline : <h3>{tagline}</h3>}
            <p>
                {children}
            </p>
        </div>
        <div className="button-area">
            {buttons.map((button: any, index: number) => (<span key={index}> {button} </span>))}
        </div>
    </div>
    )
}
function Tablet ({title,tagline,children,className,buttons,imgOne,imgTwo,down}:any) {
    let isReactElement = tagline?.type;
    return (
        <div className={`lmntl-card-mobile tablet ${down?"down":""} ${className || ""}`}>
            <img src={imgTwo} className={"background"} alt="couch reading a book"/>
            <img src={imgOne} className={"tablet"}  alt="couch reading a book"/>
            <div className="lmntl-text-content tablet">
                <h2>{title}</h2>
                {isReactElement ? tagline : <h3>{tagline}</h3>}
                <p>
                    {children}
                </p>
            </div>
            <div className="button-area">
                {buttons.map((button: any, index: number) => (<span key={index}> {button} </span>))}
            </div>
        </div>
    )
}



function ScrollCard (props:any){
    const { mobile,tablet } = props;


    return(<>
            { !mobile && <Desktop {...props} /> }
            {  mobile && !tablet && <Mobile  {...props} />}
            {  mobile && tablet && <Tablet {...props}/> }
            </>
    )

}



export default ScrollCard;