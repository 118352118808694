// @ts-nocheck
import React,{useState} from "react";
import energy from "../../static/book.png";
import energyBack from "../../static/sand.png";
import yoga from "../../static/yoga.jpg";
import waterDrops from "../../static/waterdrops.png";
import desk from "../../static/desk.png";
import leaf from "../../static/leaf.png";
import couch from "../../static/couch.png";
import woodGrain from "../../static/woodgrain.png";
import {URLS} from "../../pages";
import {StaticImage} from "gatsby-plugin-image";
import Parallax from "../parallax/Parallax";
import ElementalButton from "../lmntl-button/button";
import ScrollCard from "../scrollCard/scrollCard";
import "./Pillars.scss";
import useWindow from "../../hooks/useWindow";


function Mobile(props) {
    return (
        <div className="lmntl-landing mobile">
            <div className={`container ${props.tablet ? "tablet" : ""}`}>
                <StaticImage
                    src={"../../static/landingBackground.png"}
                    alt={"test"}
                    layout={"fullWidth"}
                    quality={100}
                />
                <div className="text-content">
                    <h1>Live your <wbr/> elemental nature.</h1>
                    <p>
                        LMNTL is about living in your element. As individual
                        human beings, we are all composed of the unique elements
                        that bring us alive. Over time, we lose touch with these
                        essential parts of ourselves which can lead to a lack of
                        trust with our true nature and therefore the world around
                        us. LMNTL is a platform that provides the opportunity to
                        uncover those elements and begin, again, to live a life of
                        purpose and fulfillment that starts within and
                        then emerges into your home, your work and your community.
                    </p>
                </div>
                <div className="chevron"/>
            </div>
            <ScrollCard
                tablet={props.tablet}
                mobile={true}
                imgOne={energy}
                imgTwo={energyBack}
                className={"first"}
                title={"Guided Discovery"}
                tagline={"Reconnect, define and sustainably live into your purpose."}
                buttons={[<ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>,
                    <ElementalButton to={URLS.consultation}>schedule consultation</ElementalButton>,
                    <ElementalButton to={`${URLS.podia}guided-discovery`}>Learn More</ElementalButton>]}
            >
                Witness the emergence of your truest self by reconnecting with your individual
                and vocational purpose. Via Lifework, the disciplines of courageous questioning,
                visioning and journaling, together we discover your elemental nature - what brings
                you alive. Guided Discovery is a platform to empower anyone looking for
                fulfillment or expansion in their relationships, career, or business, and the tools and
                resources to sustainably live your purpose. Optional following Guided Discovery,
                together we create a purposeful workflow for implementation to express the by-
                products of your discovery through creative writing, design and outreach.
            </ScrollCard>
            <ScrollCard
                tablet={props.tablet}
                mobile={true}
                imgOne={yoga}
                imgTwo={waterDrops}
                title={"inner ground"}
                tagline={"Relearn what it means to come fully alive."}
                buttons={[<ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>,
                    <ElementalButton to={URLS.consultation}>schedule consultation</ElementalButton>,
                ]}
                down
            >
                This sacred journey and homecoming happens through
                customized yoga, adventure, meditative, and conversation
                based guided experiences that engage mind, body and spirit.
                Inner Ground creates the challenge and builds the fundamental
                disciplines to empower you to take ownership of your healing
                and growth as an individual and in community. Our offerings
                include customized individual or group retreats and workshops,
                as well as customized individual or group yoga classes.
            </ScrollCard>
            <ScrollCard
                tablet={props.tablet}
                mobile={true}
                imgOne={desk}
                imgTwo={leaf}
                title={"course offerings"}
                tagline={"Transformation starts with a commitment to yourself."}
                buttons={[<ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>,
                    <ElementalButton to={URLS.consultation}>schedule consultation</ElementalButton>,
                ]}
            >
                Take ownership of manifesting the life you want and creating the daily
                disciplines to pursue it with the accountability of self-paced & self-guided
                discovery. We know the power of working one-on-one through Guided
                Discovery as well as the power of doing the work in solitude. Our
                coursework will mirror the Guided Discovery of values, true north,
                boundaries & rituals and polarities, and will guide you on a journey of
                self-discovery through videos, downloads, and Lifework to be completed
                at your own pace. We create the structure and you flow within it to fit
                your lifestyle & needs (there are no rules!). Each course includes access for
                you and one additional person.
            </ScrollCard>
            <ScrollCard
                tablet={props.tablet}
                mobile={true}
                imgOne={couch}
                imgTwo={woodGrain}
                title={"community reflections"}
                tagline={<h3>
                    “In the end, the cure for numbing is developing tools and
                    practices that allow you to lean into discomfort and renew
                    your spirit.” - Brené Brown
                </h3>}
                buttons={[
                    <ElementalButton to={URLS.consultation}> join the community </ElementalButton>,
                    <ElementalButton to={URLS.consultation}> view current offerings </ElementalButton>,
                    <ElementalButton to={URLS.podia}> community platform </ElementalButton>,
                    <ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>
                ]}
                down
            >
                LMNTL is a platform for discovery and expression - it’s also a place for us to live our
                work. We invite you to join us each month as we reflect together on what we are
                reading, listening to, writing, and learning. Join the LMNTL Community to receive
                monthly reflections with meditations, links to our mentors, and any other discoveries
                we make along the way.
                <br/>
                <br/>
                For general inquiries or to reach out and say hello, please contact us below - we look
                forward to hearing from you!
            </ScrollCard>
            <div className="mobile-vertical-spacer"/>
        </div>
    );
}

function Desktop({style}: { style?: any }) {
    const hFix = {...style, ...{height: "90vh", minHeight: "0"}}
    return (
        <Parallax
            className={"horizontal has-scroll-dragging"}
            style={hFix}
            options={{
                smooth: true,
                direction: "horizontal",
                gestureDirection: "both",
                tablet:{
                    breakpoint:0
                }
            }
            }>
            <div className="landing" data-scroll-section>
                <div data-scroll data-scroll-speed="1" className="content-wrapper has-bar">
                    <h1>Live your elemental nature.</h1>
                    <p>
                        LMNTL is about living in your element. As individual <br/>
                        human beings, we are all composed of the unique elements <br/>
                        that bring us alive. Over time, we lose touch with these <br/>
                        essential parts of ourselves which can lead to a lack of <br/>
                        trust with our true nature and therefore the world around <br/>
                        us. LMNTL is a platform that provides the opportunity to <br/>
                        uncover those elements and begin, again, to live a life of <br/>
                        purpose and fulfillment that starts within and <br/>
                        then emerges into your home, your work and your community. <br/>
                    </p>
                </div>
                <StaticImage src={"../../static/landingBackground.png"} alt={"woods hiking"} quality={100}/>
            </div>
            <ScrollCard
                title={"Guided Discovery"}
                className={"first"}
                tagline={"Reconnect, define and sustainably live into your purpose."}
                imgOne={energy}
                imgTwo={energyBack}
                buttons={[<ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>,
                    <ElementalButton to={URLS.consultation}>schedule consultation</ElementalButton>,
                    <ElementalButton to={`${URLS.podia}guided-discovery`}>Learn More</ElementalButton>]}>

                Witness the emergence of your truest self by reconnecting with your individual <br/>
                and vocational purpose. Via Lifework, the disciplines of courageous questioning, <br/>
                visioning and journaling, together we discover your elemental nature - what brings <br/>
                you alive. Guided Discovery is a platform to empower anyone looking for <br/>
                fulfillment or expansion in their relationships, career, or business, and the tools and <br/>
                resources to sustainably live your purpose. Optional following Guided Discovery, <br/>
                together we create a purposeful workflow for implementation to express the by- <br/>
                products of your discovery through creative writing, design and outreach. <br/>
            </ScrollCard>
            <div className="spacer" data-scroll-section/>
            <ScrollCard
                title={"inner ground"}
                tagline={"Relearn what it means to come fully alive."}
                imgOne={yoga}
                imgTwo={waterDrops}
                buttons={[<ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>,
                    <ElementalButton to={URLS.consultation}>schedule consultation</ElementalButton>,
                    <ElementalButton to={URLS.podia}> community platform </ElementalButton>
                ]}
                down
            >
                This sacred journey and homecoming happens through <br/>
                customized yoga, adventure, meditative, and conversation <br/>
                based guided experiences that engage mind, body and spirit. <br/>
                Inner Ground creates the challenge and builds the fundamental <br/>
                disciplines to empower you to take ownership of your healing <br/>
                and growth as an individual and in community. Our offerings <br/>
                include customized individual or group retreats and workshops, <br/>
                as well as customized individual or group yoga classes. <br/>

            </ScrollCard>
            <div className="spacer" data-scroll-section/>
            <ScrollCard
                title={"course offerings"}
                tagline={"Transformation starts with a commitment to yourself."}
                imgOne={desk}
                imgTwo={leaf}
                buttons={[<ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>,
                    <ElementalButton to={URLS.consultation}>schedule consultation</ElementalButton>,
                ]}
            >
                Take ownership of manifesting the life you want and creating the daily <br/>
                disciplines to pursue it with the accountability of self-paced & self-guided <br/>
                discovery. We know the power of working one-on-one through Guided <br/>
                Discovery as well as the power of doing the work in solitude. Our <br/>
                coursework will mirror the Guided Discovery of values, true north, <br/>
                boundaries & rituals and polarities, and will guide you on a journey of <br/>
                self-discovery through videos, downloads, and Lifework to be completed <br/>
                at your own pace. We create the structure and you flow within it to fit <br/>
                your lifestyle & needs (there are no rules!). Each course includes access for <br/>
                you and one additional person. <br/>

            </ScrollCard>
            <div className="spacer" data-scroll-section/>
            <ScrollCard
                title={"community & reflections"}
                tagline={<h2>
                    “In the end, the cure for numbing is developing tools and <br/>
                    practices that allow you to lean into discomfort and renew <br/>
                    your spirit.” - Brené Brown
                </h2>}
                imgOne={couch}
                imgTwo={woodGrain}
                buttons={[
                    <ElementalButton to={URLS.community}> join the community </ElementalButton>,
                    <ElementalButton to={URLS.podia}> community platform </ElementalButton>,
                    <ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>
                ]}
                down
            >
                LMNTL is a platform for discovery and expression - it’s also a place for us to live our <br/>
                work. We invite you to join us each month as we reflect together on what we are <br/>
                reading, listening to, writing, and learning. Join the LMNTL Community to receive <br/>
                monthly reflections with meditations, links to our mentors, and any other discoveries <br/>
                we make along the way. <br/>
                <br/>
                For general inquiries or to reach out and say hello, please contact us below - we look <br/>
                forward to hearing from you! <br/>
            </ScrollCard>
            <div className="spacer" data-scroll-section/>
            <div className="spacer" data-scroll-section/>
        </Parallax>
    )
}

export default function Pillars(props) {
    const {mobile,from} = props;
    const [lastSize, setLastSize] = useState(0);
    function handleResize(){
        let width = document.body.clientWidth;
        if(width === lastSize)return;
        else{
            setLastSize(width);
        }
    }
    useWindow(()=>{
        setLastSize(document.body.clientWidth);
        window.addEventListener("resize",handleResize);
        return ()=> window.removeEventListener("resize",handleResize);
    },[]);

    return (
        <>
            { mobile && <Mobile tablet={lastSize > 800} {...props}/>}
            {!mobile && <Desktop {...props}/>}
        </>
    )

}
