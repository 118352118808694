export default function scrollHandler (res:()=>void,rej:()=>void) {
    let scroll = Math.round(window.scrollY);
    let height = document.body.getBoundingClientRect().height;
    if(scroll > height) {
        res()

    }else{
        rej();

    }
}