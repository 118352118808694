import React from 'react';
import "./hamburger.scss";
import {isMobile} from "react-device-detect";

const Hamburger = ({handleClick,open}:{handleClick:()=>void,open:string}) => {

    return (
        <div onClick={handleClick} className={`lmntl-hamburger ${open} ${isMobile&&"mobile"}`}>
            <span className={"first-child"}/>
            <span className={"second-child"}/>
            <span className={"third-child"}/>
        </div>
    );
};

export default Hamburger;