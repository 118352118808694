 import React, {useContext} from "react";
 import {heightContext} from "../../layout/layout";
 import {isSafari} from "react-device-detect";
 import About from "../../about/about";
 import Contact from "../../contact/contact";
 import Pillars from "../../pillars/Pillars";


let style = (val:number) => ({transform :`translate3d(0, ${val}px, 0)`});


export default function MainPageDesktop () {
        let height = useContext(heightContext);
    return (
        <>
            <Pillars style={style(height)}/>
            <About style={style(height-3)} />
            <Contact style={style(height-3)} />
        </>
    )
}