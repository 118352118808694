//@ts-nocheck
import React from "react";
import {isMobile} from "react-device-detect";
import useWindow from "../../hooks/useWindow";
import scrollHandler from "../../helpers/belowFold";
import {useState} from "react";
import PageController from "../../helpers/pageController";
import up from "../../static/up.png";
import LR from "../../static/LR.png";
import down from "../../static/down.png";
import standard from "../../static/default.png";
import Header from "../header/header";
import Footer from "../footer/footer";
import Seo from "../seo/seo";

export const heightContext = React.createContext(0);

let controller = new PageController();

const Layout = ({children, page, lockedOut}: { lockedOut?: boolean, children: any, page?: "string" }) => {
    const [belowFold, setBelowFold] = useState(false);
    const [height, setHeight] = useState(0);
    const [domLoaded, setDomLoaded] = useState(false);
    const [mobile, setMobile] = useState(isMobile);

    useWindow(() => {
        window.onclick = ()=>{};
        window.onmousemove = ()=>{};
        window.onclick = () => {};
        let ele = document.querySelector("main.lmntl-content") as HTMLElement;

        let contentHeight = ele.clientHeight;
        let heights = controller.calcPageValues(contentHeight);

        if (height === 0) {
            setHeight(heights);
        }

        let top = controller.detectClickArea(ele, 0, 20, "y", () => controller.increaseIndex())
        let bottom = controller.detectClickArea(ele, 80, 100, "y", () => controller.decreaseIndex());
        let middle = controller.detectClickArea(ele, 20, 80, "y")

        window.onmousemove = (e) => {
            let option = {
                "n-resize": `url("${up}") , n-resize`,
                "ew-resize": `url("${LR}"), ew-resize`,
                "s-resize": `url("${down}"), s-resize`,
                "default": `url("${standard}"), default`
            }
            let t = top(e, true);
            let m = middle(e, true);
            let b = bottom(e, true);
            if (t) {
                document.body.style.cursor = option["n-resize"];
            }
            if (controller.currentIndex === 0 && m) {
                document.body.style.cursor = option["ew-resize"]
            } else if (m) {
                document.body.style.cursor = option["default"]
            }
            if (b) {
                document.body.style.cursor = option["s-resize"]
            }
            if (!t && !m && !b) {
                document.body.style.cursor = option["default"]
            }
        }
        window.onclick = (e) => {
            if (!lockedOut) {
                top(e);
                middle(e);
                bottom(e);
                heights = controller.calcPageValues(contentHeight);
                setHeight(heights);
            }
        };
        window.onresize = (e)=>{
            contentHeight = ele.clientHeight;
            setHeight(controller.calcPageValues(contentHeight));
        }
        if(mobile){
            window.onclick = ()=>{};
            window.onmousemove = ()=>{};
            window.onclick = () => {};
        }
        window.addEventListener("scroll", () => {
            scrollHandler(
                () => {
                    setBelowFold(true);
                    document.body.classList.add("below-fold")
                },
                () => {
                    setBelowFold(false);
                    document.body.classList.remove("below-fold")
                }
            )
        })
        setMobile(isMobile);
        setDomLoaded(true);

    }, [mobile,lockedOut]);
    return (
        <>
            {/* @ts-ignore */}
            <Seo/>
            <heightContext.Provider value={height}>
                {domLoaded && <Header controller={controller} isMobile={mobile}/>}
                {!mobile && <div className="lmntl-blank-spacer" style={{
                    height: "10vh",
                    display: "block"
                }}/>}
                <main className={`lmntl-content ${mobile && "mobile"} `}>
                    {children}
                </main>
                {domLoaded && <Footer mobile={mobile} page={page || controller.pages[controller.currentIndex * -1]}
                                      belowFold={belowFold}/>}
            </heightContext.Provider>
        </>
    )
}

export default Layout;