import React from 'react';

import Instagram from "../instagram/instagram";
import Twitter from "../twitter/twitter";
import "./iconContainer.scss";
import {URLS} from "../../pages";
const IconContainer = ({mobile,belowFold}:{mobile:boolean,belowFold:boolean}) => {
    let color = mobile ? "transparent" : "";
    if ( belowFold && mobile)  {
        color = "var(--lmntl-color-space)";
    }
    return (
        <div className="lmntl-icon-container">
            <a href={URLS.instagram} target={"_blank"}>
                <Instagram background={color}/>
            </a>
        </div>
    );
};


export default IconContainer;