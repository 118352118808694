import React from 'react';
import "./button.scss";


const ElementalButton = ({children, to, handleClick}: { children: any, to?: string, handleClick?: any }) => {
    return (
        to ?
            <a className={"lmntl-heart-button"} target={"_blank"} href={to || ""}>
                {children}
            </a> :
            <button onClick={handleClick} className={"lmntl-heart-button"}>
                {children}
            </button>
    );
};


export default ElementalButton;