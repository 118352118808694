import React from "react";
import {isMobile} from "react-device-detect";
import {URLS} from "../../pages";
import ElementalButton from "../lmntl-button/button";
import "./contact.scss";

export default function Contact(props: any) {
    const {style, className}: { style?: any, className?: string } = props;
    return (
        <div className={`lmntl-contact ${isMobile && "mobile"} ${className}`} style={style}>
            <div className="lmntl-bar"/>
            <div className="lmntl-contact-content">
                <div className="lmntl-text-content">
                    <h2>Connect</h2>
                    <p>
                        We invite you to connect with us to have a conversation about your vision,
                        upcoming projects, and how we could collaborate to bring it to fruition.
                    </p>
                </div>
                <div className="lmntl-buttons">
                    <ElementalButton to={URLS.podia}>community platform</ElementalButton>
                    <ElementalButton to={URLS.community}>join the community</ElementalButton>
                    <ElementalButton to={URLS.honeyBook}> contact us </ElementalButton>
                    <ElementalButton to={URLS.consultation}>schedule consultation</ElementalButton>
                </div>
            </div>
        </div>
    )
}
