import React, {useState} from 'react';
import Logo from "../logo/logo";
import Hamburger from "../hamburger/hamburger";
import "./header.scss";
import {Link} from "gatsby";
import PageController from "../../helpers/pageController";

function ElementalLink({controller,clickHandle, index, mobile, pathMobile, children, active}:
                           {
                               controller: PageController
                               mobile: boolean,
                               pathMobile: string,
                               children?: any,
                               index: number,
                               active: boolean,
                               clickHandle?:any,
                           }) {
    return (mobile ?
            <Link
                to={pathMobile}
                activeStyle={{color: "var(--lmntl-color-energy)"}}
            >
                {children}
            </Link> :
            <a style={{
                textDecoration: "none",
                color: `${active ? "var(--lmntl-color-energy)" : "var(--lmntl-color-bone-tint)"}`,
            }} className="link"
               onClick={() => {
                   controller.setIndex(index)
                   clickHandle();
               }}
            >
                {children}
            </a>
    )
}

const Header = ({isMobile, controller}: { controller: PageController, isMobile: boolean }) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(!open);
    let isOpen = open ? "open" : "";
    let currentPosition = controller.currentIndex * -1;
    return (
        <header className={`lmntl-header ${isOpen} ${isMobile && "mobile"}`}>
            <nav className={"drop-down"}>
                <ul>
                    <li>
                        <ElementalLink
                            controller={controller}
                            index={0}
                            mobile={isMobile}
                            pathMobile={"/"}
                            clickHandle={handleClick}
                            active={currentPosition === 0}
                        >
                            home
                        </ElementalLink>

                    </li>
                    <li>
                        <ElementalLink
                            controller={controller}
                            index={-1}
                            mobile={isMobile}
                            pathMobile={"/about"}
                            clickHandle={handleClick}
                            active={currentPosition === 1}
                        >
                            about us
                        </ElementalLink>
                    </li>
                    <li>
                        <ElementalLink
                            controller={controller}
                            index={-2}
                            mobile={isMobile}
                            pathMobile={"/connect"}
                            clickHandle={handleClick}
                            active={currentPosition === 2}
                        >
                            connect
                        </ElementalLink>
                    </li>
                </ul>
            </nav>
            <ElementalLink
                controller={controller}
                mobile={isMobile}
                pathMobile={"/"}
                index={0}
                clickHandle={()=>{}}
                active={false}
            >
                <Logo/>
            </ElementalLink>

            <Hamburger open={isOpen} handleClick={handleClick}/>
        </header>
    );
};


export default Header;
