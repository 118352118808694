import React from 'react';
import "./pageIndicator.scss";

const PageIndicator = (props: { nextPage: String; }) => {
    const {nextPage} = props;
    return (
        <div id={"key"} className="lmntl-page-indicator">
                  <span className="text">
                      {nextPage}
                  </span>
        </div>
    );
};


export default PageIndicator;