import React from 'react';
import "./Footer.scss";
import PageIndicator from "../pageIndicator/pageIndicator";
import IconContainer from "../iconContainer/iconContainer";


const Footer = ({page,mobile,belowFold}:{page?:string,belowFold:boolean,mobile:boolean}) => {

    return (
        <footer className={`lmntl-footer ${mobile && "mobile"}`}>
            {!mobile && <PageIndicator nextPage={page || ""}/>}
            <IconContainer mobile={mobile} belowFold={belowFold}/>
        </footer>
    );
};

export default Footer;