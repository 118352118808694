import useWindow from "../../hooks/useWindow";
import React from "react";

export default function Parallax ({options,className,style,...rest}:any){
    useWindow(init,[]);
    function init() {
        let scroller:any;
        import("locomotive-scroll")
            .then(LocomotiveScroll => {
                scroller = new LocomotiveScroll.default({
                    ...options,
                    ...{el: document.querySelector('[data-scroll-container]')
                    }
                });
            })
            .then(()=>{
                let callback = rest?.callback;
                if(callback){
                    callback(scroller);
                }
            })
    }
    return (
        <div data-scroll-container style={style} className={className} >
            {rest.children}
        </div>
    )
};