import React from 'react';


function Logo({color = "#F2EEE6"}) {
    return (
        <svg className={"lmntl-logo"} width="137" height="57" viewBox="0 0 137 57" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5207 13.7866H19.4809C19.6759 13.7866 19.863 13.8638 20.0013 14.0012C20.1396 14.1387 20.218 14.3253 20.2192 14.5203V37.3832C20.2192 37.4607 20.2497 37.5351 20.304 37.5904C20.3584 37.6456 20.4324 37.6773 20.5099 37.6785H32.3497C32.5443 37.6785 32.7309 37.7558 32.8685 37.8934C33.006 38.031 33.0833 38.2176 33.0833 38.4122V42.5049C33.0833 42.6999 33.0062 42.887 32.8687 43.0253C32.7313 43.1636 32.5447 43.2419 32.3497 43.2431H18.1982C17.0283 43.2431 15.9063 42.7784 15.0791 41.9511C14.2518 41.1239 13.7871 40.0019 13.7871 38.832V14.5203C13.7871 14.3257 13.8644 14.1391 14.002 14.0015C14.1396 13.8639 14.3262 13.7866 14.5207 13.7866Z"
                fill={`${color}`}/>
            <path
                d="M104.574 13.7866H109.539C109.734 13.7866 109.92 13.8639 110.058 14.0015C110.196 14.1391 110.273 14.3257 110.273 14.5203V37.3832C110.273 37.4615 110.304 37.5366 110.359 37.592C110.415 37.6474 110.49 37.6785 110.568 37.6785H123.322C123.516 37.6785 123.703 37.7558 123.84 37.8934C123.978 38.031 124.055 38.2176 124.055 38.4122V42.5049C124.055 42.6999 123.978 42.887 123.841 43.0253C123.703 43.1636 123.517 43.2419 123.322 43.2431H108.252C107.082 43.2431 105.96 42.7784 105.133 41.9511C104.306 41.1239 103.841 40.0019 103.841 38.832V14.5203C103.841 14.3257 103.918 14.1391 104.056 14.0015C104.193 13.8639 104.38 13.7866 104.574 13.7866Z"
                fill={`${color}`}/>
            <path
                d="M52.352 13.7866C51.5327 13.7849 50.7293 14.0121 50.0322 14.4425C49.3352 14.873 48.7722 15.4896 48.4069 16.2229L41.4857 30.0652C41.4734 30.0895 41.4545 30.1098 41.4313 30.124C41.4081 30.1382 41.3814 30.1457 41.3542 30.1457C41.327 30.1457 41.3003 30.1382 41.2771 30.124C41.2539 30.1098 41.2351 30.0895 41.2227 30.0652L34.3015 16.2229C33.9353 15.4903 33.3722 14.8743 32.6754 14.4439C31.9785 14.0136 31.1755 13.786 30.3565 13.7866H27.385C27.1904 13.7866 27.0038 13.8639 26.8662 14.0015C26.7287 14.1391 26.6514 14.3257 26.6514 14.5203V30.5082C26.6514 30.7032 26.7285 30.8903 26.866 31.0286C27.0034 31.1669 27.19 31.2452 27.385 31.2465H32.3498C32.5448 31.2452 32.7314 31.1669 32.8688 31.0286C33.0063 30.8903 33.0834 30.7032 33.0834 30.5082V25.4327C33.0839 25.4001 33.0952 25.3686 33.1154 25.3432C33.1356 25.3176 33.1637 25.2995 33.1952 25.2917C33.2268 25.2838 33.2601 25.2865 33.29 25.2995C33.3198 25.3125 33.3445 25.335 33.3603 25.3635L39.3125 37.2679C39.3733 37.3897 39.4667 37.4922 39.5825 37.5639C39.6982 37.6356 39.8316 37.6737 39.9677 37.6739H42.7362C42.8731 37.6745 43.0074 37.6368 43.124 37.5651C43.2406 37.4933 43.3348 37.3904 43.396 37.2679L49.3666 25.3865C49.381 25.3554 49.4058 25.3303 49.4367 25.3155C49.4675 25.3006 49.5026 25.297 49.5359 25.3052C49.5691 25.3134 49.5985 25.3328 49.619 25.3603C49.6395 25.3878 49.6498 25.4215 49.6481 25.4557V42.4587C49.6481 42.6537 49.7252 42.8408 49.8627 42.9791C50.0001 43.1175 50.1868 43.1958 50.3818 43.197H55.3465C55.5415 43.1958 55.7281 43.1175 55.8656 42.9791C56.003 42.8408 56.0802 42.6537 56.0802 42.4587V14.5203C56.0802 14.3257 56.0029 14.1391 55.8653 14.0015C55.7277 13.8639 55.5411 13.7866 55.3465 13.7866H52.352Z"
                fill={`${color}`}/>
            <path
                d="M78.1128 26.4668V30.6518C78.1105 30.6839 78.0982 30.7145 78.0774 30.7392C78.0566 30.7638 78.0286 30.7812 77.9973 30.7889C77.966 30.7966 77.9331 30.7942 77.9033 30.782C77.8735 30.7698 77.8483 30.7484 77.8314 30.721L70.1627 16.1589C69.788 15.4484 69.2263 14.8537 68.5384 14.439C67.8505 14.0243 67.0624 13.8053 66.2592 13.8057H63.2092C63.0147 13.8057 62.828 13.883 62.6905 14.0205C62.5529 14.1581 62.4756 14.3447 62.4756 14.5393V42.4593C62.4756 42.6543 62.5527 42.8414 62.6902 42.9797C62.8276 43.118 63.0142 43.1964 63.2092 43.1976H68.174C68.369 43.1964 68.5556 43.118 68.6931 42.9797C68.8305 42.8414 68.9077 42.6543 68.9077 42.4593V25.4378C68.9082 25.4053 68.9194 25.3738 68.9396 25.3483C68.9598 25.3228 68.9879 25.3047 69.0195 25.2968C69.0511 25.289 69.0844 25.2917 69.1142 25.3047C69.144 25.3177 69.1688 25.3402 69.1845 25.3686L76.8762 40.7521C77.2422 41.4852 77.8052 42.1019 78.502 42.533C79.1988 42.9642 80.0019 43.1927 80.8213 43.193H83.7928C83.9886 43.193 84.1764 43.1152 84.3148 42.9767C84.4533 42.8383 84.5311 42.6505 84.5311 42.4547V26.4668C84.5299 26.2718 84.4515 26.0852 84.3132 25.9477C84.1749 25.8103 83.9878 25.7331 83.7928 25.7332H78.8465C78.6519 25.7332 78.4653 25.8104 78.3277 25.948C78.1901 26.0856 78.1128 26.2722 78.1128 26.4668Z"
                fill={`${color}`}/>
            <path
                d="M78.1123 14.5203V18.5622C78.1123 18.7572 78.1894 18.9443 78.3269 19.0826C78.4644 19.221 78.651 19.2993 78.846 19.3005H90.6811C90.7587 19.3005 90.8331 19.331 90.8884 19.3853C90.9436 19.4397 90.9752 19.5137 90.9765 19.5912V42.4587C90.9765 42.6537 91.0536 42.8408 91.1911 42.9791C91.3285 43.1174 91.5151 43.1958 91.7101 43.197H96.6703C96.8661 43.197 97.0539 43.1192 97.1923 42.9808C97.3308 42.8423 97.4085 42.6545 97.4085 42.4587V14.5203C97.4073 14.3253 97.329 14.1387 97.1907 14.0012C97.0523 13.8638 96.8653 13.7866 96.6703 13.7866H78.846C78.6514 13.7866 78.4648 13.8639 78.3272 14.0015C78.1896 14.1391 78.1123 14.3257 78.1123 14.5203Z"
                fill={`${color}`}/>
            <path
                d="M124.055 28.6721V4.41109C124.055 3.2412 123.59 2.11921 122.763 1.29197C121.936 0.464734 120.814 0 119.644 0H4.41109C3.2412 0 2.11921 0.464734 1.29197 1.29197C0.464734 2.11921 0 3.2412 0 4.41109V52.564C0 53.7339 0.464734 54.8559 1.29197 55.6831C2.11921 56.5103 3.2412 56.9751 4.41109 56.9751H123.321C123.516 56.9751 123.703 56.8978 123.84 56.7602C123.978 56.6226 124.055 56.436 124.055 56.2414V52.1949C124.055 52.0003 123.978 51.8137 123.84 51.6761C123.703 51.5385 123.516 51.4612 123.321 51.4612H5.80915C5.73083 51.4612 5.65574 51.4301 5.60036 51.3747C5.54498 51.3193 5.51387 51.2442 5.51387 51.1659V5.80917C5.51387 5.73085 5.54498 5.65574 5.60036 5.60036C5.65574 5.54498 5.73083 5.51386 5.80915 5.51386H118.25C118.328 5.51507 118.402 5.54671 118.456 5.60196C118.511 5.65721 118.541 5.73164 118.541 5.80917V28.6721C118.542 28.8671 118.621 29.0537 118.759 29.1911C118.897 29.3286 119.084 29.4057 119.279 29.4057H123.321C123.516 29.4057 123.703 29.3284 123.84 29.1909C123.978 29.0533 124.055 28.8667 124.055 28.6721Z"
                fill={`${color}`}/>
            <path
                d="M135.332 43.1927H131.18C131.001 43.1915 130.831 43.1201 130.705 42.994C130.578 42.868 130.507 42.6973 130.506 42.519V38.3663C130.507 38.1885 130.579 38.0183 130.705 37.893C130.831 37.7676 131.002 37.6973 131.18 37.6973H135.332C135.51 37.6973 135.68 37.7678 135.805 37.8932C135.931 38.0187 136.001 38.1889 136.001 38.3663V42.519C136.001 42.6969 135.931 42.8675 135.806 42.9937C135.68 43.1199 135.51 43.1915 135.332 43.1927Z"
                fill={`${color}`}/>
        </svg>
    );
}

export default Logo;