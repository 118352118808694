export default class PageController {
    pages:string[];
    currentIndex:number;
    constructor(){
        this.pages = ["about us","connect","home"];
        this.currentIndex = 0;
    }
    setIndex = (val:number) => {
        this.currentIndex = val;
    }
    increaseIndex  () {
        if(this.currentIndex < 0){
            this.currentIndex++;
        }
    }
    decreaseIndex  () {
        if(this.currentIndex > 0 - this.pages.length + 1 ){
            this.currentIndex--;
        }else{
            this.currentIndex = 0;
        }
    }
    calcPageValues(height:number){
        if(height) {
            let res:any[] = [];
            let temp = this.pages.map(() => height);
            temp.reduce((acc, next, index) => {
                res[index] = acc;
                return acc + next;
            }, this.currentIndex * height);
            return res[0];
        }else{
            throw("undefined for some reason")
        }
    }
    detectClickArea (element:HTMLElement,min:number,max:number,axis:string,callback?:any){
        if(!callback){
            callback = () => {}
        }
        // min and max are percentages of the element's width or height
        return (e:any,killSwitch?:boolean) => {
            let pos = {x:e.clientX,y:e.clientY};
            let rect = element.getBoundingClientRect();
            let ele = document.querySelector("main.lmntl-content");
            let footer = document.querySelector("footer.lmntl-footer");
            let isContent = (ele?.contains(e.target)|| e.target === ele);
            let isFooter = (e.target === footer || footer?.contains(e.target));
            if(isContent|| isFooter) {
                if (axis.toLowerCase() === 'x') {
                    if (pos.x > rect.left + rect.width * (min / 100) && pos.x < rect.left + rect.width * (max / 100)) {
                        killSwitch || callback();
                        return true;
                    } else {
                        return false;
                    }
                } else if (axis.toLowerCase() === 'y') {
                    if (pos.y > rect.top + rect.height * (min / 100) && pos.y < rect.top + rect.height * (max / 100)) {
                        killSwitch || callback();
                        return true
                    } else {
                        return false
                    }
                } else {
                    console.log("error");
                }
            }else{
                return false;
            }
        }
    }
}