// @ts-nocheck
import React, {useState} from "react"
import {isMobile,isIOS} from "react-device-detect";
import useWindow from "../hooks/useWindow";
import Layout from "../_components/layout/layout";
import Pillars from "../_components/pillars/Pillars";
import MainPageDesktop from "../_components/desktop/mainPage/main";
import Loader from "../_components/Loader/loader";



export const URLS = {
    honeyBook: "https://www.honeybook.com/widget/city_social/cf_id/5a76e185dc1452000312265a",
    consultation: "https://lmntl.hbportal.co/schedule/60eeed95db32120029b5bd91",
    community:"https://lmntl.podia.com/lmntl-community",
    podia: "https://lmntl.podia.com/",
    twitter: "https://twitter.com/lmntl_vision",
    instagram: "https://www.instagram.com/lmntl_vision/"
}



function useLoadingLogic(loadingTime: number, animatingTime: number, loopingTime: number) {
    // isLoading
    // is the bare minimum time we need to all the layout to settle in.
    const [isLoading, setLoading] = useState(true);
    // isAnimating
    // Is the offset to all the animation to finish before removing the component from the dom tree
    const [isAnimating, setIsAnimating] = useState(false);
    // isLooping
    // The original concept was to do an animated intro and then have the dot pulsing for the remainder of the time,
    // this is just to allow that to happen if need be,
    const [isLooping, setIsLooping] = useState(false);
    const [mobile,setMobile] = useState(isMobile);
    // domContent Finished
    const totalTime = loadingTime + loopingTime + animatingTime;

    useWindow(() => {
        setTimeout(() => {
            // this is for the removal of the element and is the total time.
            setMobile(isMobile);
            setLoading(false);
        }, totalTime);
        setTimeout(() => {
            // this is for when to change to the loop;
            setIsLooping(true);
        }, totalTime - loopingTime);
        setTimeout(() => {
            //this is for the exit frames to gracefully exit with a fade out
            setIsAnimating(true);
        }, totalTime - animatingTime)
    },[])


    return {isLoading, isAnimating, isLooping,mobile};
}

const IndexPage = () => {
    const {isLoading, isAnimating,mobile} = useLoadingLogic(2000, 1000, 1000);
    const [isServer,setServer] = useState(true);
    useWindow(()=> setServer(false));
    let opacity = isAnimating ? 0 : 1;
    return (
        <>
            { isLoading && <Loader opacity={opacity}  />}
            <Layout lockedOut={isLoading}>
                { !isServer &&  mobile && <Pillars from={"index"} mobile={mobile}/>}
                { !isServer && !mobile && <MainPageDesktop/>}
            </Layout>
        </>
    )
}

export default IndexPage
