// @ts-nocheck
import useWindow from "../../hooks/useWindow";
import introBackground from "../../static/loader-background.png";
import {isIOS} from "react-device-detect";
import introVideo from "../../static/intro.mp4";
import intro from "../../static/intro.gif";
import React from "react";
import "./loader.scss";

export default function Loader({opacity}: any) {
    useWindow(()=>{
        let video = document.getElementById("video");
        if(video) {
            video.play();
            return () => video.pause()
        }
    },[])
    return (
        <div
            style={{opacity}}
            className="loading"
        >
            {!isIOS && <video id={"video"} autoPlay muted style={{
                position: "absolute",
                inset: 0,
                backgroundColor: "var(--lmntl-color-ground)",
                blendMode: "multiply",
                zIndex: -1,
                width: "100%",
                height: "100%",
                objectFit: "cover"
            }}
            >
                <source src={introVideo} type="video/mp4"/>
                <p> The video didn't load right </p>
            </video>}
            {isIOS && <img src={intro} alt={""} style={{
                position: "absolute",
                width: "100vw",
            }}/>}
        </div>
    )
}
