import React from "react"
// @ts-ignore
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"


export default function Seo({ description, title, keywords}:any) {
    const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            author
            viewport
            abstract
            identifierUrl
            revisitAfter
            keywords
            author
            language
            copyright
            robots
            siteUrl
          }
        }
      }
    `
    );
    const metaDescription = description !== "" ? description : site.siteMetadata.description;
    const metaTitle = title !== "" ? title : site.siteMetadata.title;
    const metaKeywords = keywords !== "" ? keywords : site.siteMetadata.keywords;

    return (
        <Helmet
            htmlAttributes={{
                lang:'EN',
            }}
            title={metaTitle}
            defer={false}
            meta={[
                {
                    name:"name",
                    content:site.siteMetadata.name
                },
                {
                    name: "theme-color",
                    media:"(prefers-color-scheme: light)",
                    content:"#2E6F7E"
                },
                {
                    name: "theme-color",
                    media:"(prefers-color-scheme: dark)",
                    content:"#303D3E"
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name:"canonical",
                    content: "lmntl.vision"
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `LMNTL is a platform that provides the opportunity to
                   uncover those elements and begin, again, to live a life of
                    purpose and fulfillment that starts within and
                    then emerges into your home, your work and your community.`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name:'viewport',
                    content:site.siteMetadata.viewport
                },
                {
                    name:'identifier-url',
                    content:site.siteMetadata.identifierUrl
                },
                {
                    name:'abstract',
                    content:site.siteMetadata.abstract
                },
                {
                    name:'keywords',
                    content:metaKeywords
                },
                {
                    name:'author',
                    content:site.siteMetadata.author
                },
                {
                    name:'revisit-after',
                    content:site.siteMetadata.revisitAfter
                },
                {
                    name:'copyright',
                    content:site.siteMetadata.copyright
                },
                {
                    name:'robots',
                    content:site.siteMetadata.robots
                },
                {
                    name:'siteUel',
                    content:site.siteMetadata.siteUrl
                }
            ].concat()}/>
    )
}


